<template>
  <table>
    <thead>
    <tr>
      <th class="center-align">ID</th>
      <th>Контрагент</th>
      <th>Местность</th>
      <th>Адрес</th>
      <th>Сервис</th>
      <th>Эксплуатация</th>
      <th></th>
    </tr>
    </thead>

    <tbody>
    <tr v-for="(record, idx) of records" :key="record.id">
      <td class="center-align">
        <router-link :to="{path: '/objects/' + record.id}"
                     v-tooltip="'Открыть'"
                     class="btn-small btn blue darken-4">
          {{record.id}}
        </router-link>
        <div v-if="record.external_id">
          <div class="chip no-margin" v-tooltip="'Внешний ID'">{{record.external_id}}</div>
        </div>
      </td>
      <td>
        <router-link :to="{path: '/customers/' + record.customer.id}">
          {{record.customer.name}}
        </router-link>
      </td>
      <td>{{record.locality}}</td>
      <td>{{record.address}}</td>
      <td>
        <section v-if="record.service_engineer">{{record.service_engineer.last_name}} {{record.service_engineer.first_name}}</section>
        <section v-else>Нет</section>
      </td>
      <td>
        <section v-if="record.maintenance_engineer">{{record.maintenance_engineer.last_name}} {{record.maintenance_engineer.first_name}}</section>
        <section v-else>Нет</section>
      </td>
      <td>
        <a
          v-if="record.location"
          v-tooltip="'Открыть на карте'"
          class="btn-small btn blue darken-4"
          target="_blank"
          :href="record.location | locationLink(17)">
          <i class="material-icons">location_on</i>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    props: {
      records: {
        required: true,
        type: Array
      }
    },
  }
</script>
